import * as React from "react";
import { useEffect, useState } from "react";
import { LoadableSelectItem } from "./LoadableSelect";
import TaxService from "../../services/TaxService";
import { Country } from "../../store/common/types";
import { Alert, Select, Spin } from "antd";

export interface Props {
  country: Country;
  value?: number;
  onChange?: (value: number) => void;
  placeholder? : string
}

export const TaxLoadableSelect: React.FunctionComponent<Props> = (props) => {

  const [error, setError] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [tax, setTax] = useState<LoadableSelectItem>(null);
  const [taxes, setTaxes] = useState<LoadableSelectItem[]>([]);

  const {placeholder = "Select tax key"} = props;

  function getTaxes(usePreloadedList: boolean = false): Promise<LoadableSelectItem[]> {

    if (usePreloadedList && taxes.length > 0) {
      return Promise.resolve(taxes);
    } else {
      return TaxService.getTaxValues(props.country)
        .then(values => {

          return values.map(
            (tax) => {
                let rate = tax.values.filter(validity => validity.valid_until == null).map((valid) => valid.rate)
              return {
                value: tax.id,
                label: tax.name + " (" + (rate[0] * 100).toFixed(2) + "%)"
              }
            }
          );
        });
    }
  }

  useEffect(() => {

    if (props.country) {
      setFetching(true);

      getTaxes()
        .then(options => {
          setTaxes(options);
          setFetching(false);
        })
        .catch(error => {
          setError(true);
        });
    } else {
      setTaxes([]);
    }
  }, [props.country]);

  useEffect(() => {

    if (props.value || props.value === 0) {

      getTaxes(true)
        .then(taxes => {

          const foundTax = taxes.filter((tax: any) => tax.value === props.value);
          if (foundTax.length === 1) {
            setTax(foundTax[0]);
          }

        })
        .catch(error => {
          setError(true);
        });
    } else {
      setTax(null);
    }
  }, [props.value]);

  return (
    <React.Fragment>
      {
        error && <Alert message="Error loading data" type="error" showIcon closable onClose={() => {setError(false)}}/>
      }
      <Select
        value={tax || undefined}
        placeholder={placeholder}
        allowClear
        labelInValue
        filterOption={true}
        onChange={newValue => {
          setTax(newValue);
          props.onChange(newValue ? (newValue.value as number) : undefined);
        }}
        options={!fetching ? taxes : [{label: <Spin size="small" />, value: -1}]}
        style={{ width: '100%' }}
      />
    </React.Fragment>
  );
};

export default TaxLoadableSelect;
